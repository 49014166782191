<template>
	<div class="centered">
		<div>
			<!-- <PieChart :data="chart_countries"></PieChart> -->
			<h1>לנוחיותכם ריכזנו את סוגי הSMS שתצטרכו לשלוח לקראת החתונה:</h1>
			<div class="help">
				<div class="head">איך זה עובד?</div>
				<ol>
					<li>
						יש להזין את המלל אשר אתם רוצים לשלוח לאורחים על ידי לחיצה על כפתור
						העריכה.
					</li>
					<li>
						ההודעה תישלח לאורח בפורמט הנ"ל: שלום "[שם האורח], [המלל שהזנתם],
						[לינק להזמנה]".
					</li>
					<li>על המלל שתזינו יש הגבלה של 145 תווים.</li>
					<li>כל הודעה יכולה להישלח רק פעם אחת לאורח.</li>
					<li>
						הודעת תשלום באשראי/תודה לאורחים ניתן לשלוח 4 ימים לפני האירוע.
					</li>
					<li>
						על בעל האירוע האחריות להוציא מרשימת התפוצה אנשים שביקשו לצאת ממנה.
					</li>
				</ol>
			</div>
			<h1>רשימת הSMS</h1>
			<h2>סה"כ נשלחו {{ num_sms_sent }} SMS מתוך {{ max_sms }} SMS</h2>
			<div id="sms_wrapper" v-if="loaded">
				<div v-for="(item, i) in sms_list" :key="item.id" :data-id="i">
					<!-- <h3>SMS {{(i+1)}}</h3> -->
					<div class="sms_wrap">
						<div class="sms_data">
							<div class="text_info">
								<div class="sms_text">
									<h3>SMS {{ item.title }}</h3>
									<label
										class="edit"
										@click="item.edit = true"
										v-if="!item.edit"
										><i class="fas fa-edit"></i> לחץ לעריכה
									</label>
									<div
										v-if="!item.edit"
										v-html="item.msg"
										class="edit_text"
									></div>
									<form @submit.prevent="edit_sms(item)" v-if="item.edit">
										<textarea
											type="text"
											name="name"
											v-model="item.msg"
											required
											maxlength="145"
										></textarea>
										<input type="submit" class="btn" value="שמור" />
									</form>
								</div>
								<div class="sms_info">
									<h4>SMS זה נשלח ל {{ item.guest_send }} אנשים</h4>
									<h4
										v-if="max_sms >= num_sms_sent + Number(item.guest_to_send)"
									>
										SMS זה יישלח ל {{ item.guest_to_send }} אנשים
									</h4>
									<div v-else>
										<h4>
											SMS זה יישלח ל {{ max_sms - num_sms_sent }} אנשים במקום
											{{ item.guest_to_send }}
										</h4>
										<h4>
											לרכישת חבילת sms נוספת יש ללחוץ
											<router-link :to="{ name: 'sms_plans' }">כאן</router-link>
										</h4>
									</div>
								</div>
							</div>
							<div class="sms_preview">
								<h3>תצוגה מקדימה להודעה</h3>
								<div>
									<div>שלום [אורח],</div>
									{{ item.msg }}
									<div>[לינק להזמנה]</div>
								</div>
							</div>
						</div>
						<div class="btn" v-if="!item.edit" @click="send_sms(item)">
							שלח SMS
						</div>
					</div>
				</div>
			</div>
			<div class="prog_wrapper" v-else>
				<md-progress-spinner
					:md-diameter="100"
					:md-stroke="10"
					md-mode="indeterminate"
				></md-progress-spinner>
			</div>
		</div>
	</div>
</template>
<script>
// import PieChart from '../components/charts/PieChart.vue';
export default {
	// components: { PieChart },

	data() {
		return {
			plan: {},
			loaded: false,
			chart_countries: [],
			sms_list: [],
			num_sms_sent: 0,
			max_sms: null,
		};
	},
	methods: {
		send_sms(item) {
			var approve = true;
			switch (item.msg_type) {
				case "1427": {
					approve = true;
					break;
				}
				case "1428": {
					approve = true;
					break;
				}
				case "1429": {
					if (this.$store.event.days_left > 4) {
						approve = false;
					}

					break;
				}
				case "1435": {
					if (this.$store.event.days_left > 4) {
						approve = false;
					}

					break;
				}
				case "1430": {
					if (this.$store.event.days_left > 4) {
						approve = false;
					}
					break;
				}
			}
			if (approve && item.guest_to_send > 0) {
				var msg = "האם אתם בטוחים שברצונכם לשלוח SMS זה?" + "\n" + item.msg;
				this.$confirm(msg).then(() => {
					this.net_api(
						{
							action: "event_data/send_sms",
							data: { sms_type: item.msg_type, event_id: this.$store.event.id },
						},
						(data) => {
							if (data !== 1) {
								this.alert("הזמנות נשלחו");
								this.loadData();
							} else {
								this.alert("שגיאה בשליחת הזמנות");
							}
						}
					);
				});
			} else if (approve && item.guest_to_send <= 0) {
				this.alert("אין אורחים שצריכים לקבל SMS זה");
			} else {
				this.alert("טרם ניתן לשלוח SMS זה");
			}
		},
		edit_sms(item) {
			this.net_api(
				{
					action: "event_data/edit_sms",
					data: {
						event_id: item.event_id,
						msg_type: item.msg_type,
						msg: item.msg,
						id: item.id,
					},
				},
				() => {}
			);
			this.loadData();
		},

		loadData() {
			this.loaded = false;

			this.net_api(
				{
					action: "event_data/results",
					data: { event_id: this.$store.event.id },
				},
				(data) => {
					for (let i in data.data[0].sms_list) {
						data.data[0].sms_list[i].edit = false;
					}
					this.sms_list = data.data[0].sms_list;
					this.num_sms_sent = +data.data[0].sms_counter;
					setTimeout(() => {
						this.loaded = true;
					}, 500);
				}
			);
		},
	},
	mounted() {
		this.max_sms = this.$store.event.max_sms;
		this.loadData();
	},
};
</script>
<style lang="scss" scoped>
ul{text-align: right;}
.clr{clear: both;}
.prog_wrapper{margin: 0 auto;text-align: center;}

form textarea{ width: 100%;padding: 12px 20px;margin: 8px 0;box-sizing: border-box;text-align: right;border: 2px solid #ccc;border-radius: 5px;resize: vertical;}
#sms_wrapper{
    &{text-align: right;margin-top: 20px;}
    .sms_wrap {
        &{min-height: 120px;margin-bottom: 20px; background: #eeeeee;border-radius: 20px;position: relative;padding:15px;display: flex;flex-direction: column; align-items: center;}
        .sms_data {
            &{display: flex;justify-content: space-between;width:100%;}
			@media (max-width:600px) {flex-direction: column;}
            .text_info{
                &{display: flex;flex-direction: column;flex-basis: 55%;font-size:16px}
                .sms_text {
                    &{padding:10px; }
                    h4 {max-width: 60%;}
                    h3 {
						&{font-size:26px}
						@media (max-width:600px) {font-size:24px}
					}
                    form {
                        textarea {font-family: "arial";font-size:16px}
                    }
                    .edit {color: rgba(0, 0, 255, 0.558);padding-right: 10px;}
                    .edit_text {padding-top: 20px }
                }
                .sms_info {
                    &{flex-basis: 30%}
                    h4 {margin:8px}
                }
            }
            .sms_preview{display:flex; flex-direction: column;justify-content: center;flex-basis: 45%; font-size: 18px;font-weight: 500;}
        }

    }
}
</style>