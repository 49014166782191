<template v-if="loaded" id="forms_page">
	<div class="centered">
		<div class="col-md-6">
			<div class="colmd_wrap">
				<h1>פרטי האירוע:</h1>
				<div class="row">
					<div class="image">
						<button class="btn change_pic" @click="chooseFiles()">
							להעלאת תמונת רקע ראשית
						</button>
						<div
							class="pic"
							@click="chooseFiles()"
							:style="
								pic_uploaded != ''
									? 'background-image:url(' + pic_uploaded + ')'
									: event.pic_parsed
									? 'background-image:url(' + event.pic_parsed + ')'
									: 'background:#ccc'
							"
							:clickable="false"
						/>
						<div class="attach_options">
							<input
								id="fileUpload"
								type="file"
								ref="file_pic"
								hidden
								@change="uploadFile()"
							/>
							<md-progress-bar
								md-mode="indeterminate"
								v-if="pic_loaded"
							></md-progress-bar>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="image">
						<button class="btn change_pic" @click="chooseFiles(true)">
							להעלאת תמונה של ההזמנה
						</button>
						<div
							class="pic"
							@click="chooseFiles()"
							:style="
								invaite_uploaded != ''
									? 'background-image:url(' + invaite_uploaded + ')'
									: event.invitation_parsed
									? 'background-image:url(' + event.invitation_parsed + ')'
									: 'background:#ccc'
							"
							:clickable="false"
						/>
						<div class="attach_options">
							<input
								id="fileUpload_invite"
								type="file"
								ref="file"
								hidden
								@change="uploadFile(true)"
							/>
							<md-progress-bar
								md-mode="indeterminate"
								v-if="invaite_loaded"
							></md-progress-bar>
						</div>
					</div>
				</div>
				<form @submit.prevent="save()">
					<div class="field">
						<label> שם האירוע</label>
						<input type="text" v-model="event.name" />
					</div>
					<div class="field">
						<label>מיקום האירוע</label>
						<input type="text" v-model="event.location" />
					</div>
					<div class="field">
						<label>תאריך ושעת האירוע</label>
						<div class="dateTime">
							<input type="date" class="date" v-model="event.event_date" />
							<input type="time" class="time" v-model="event.event_time" />
						</div>
					</div>
					<div class="field">
						<label>הערה נוספת (לא חובה)</label>
						<input type="text" v-model="event.note" />
					</div>

					<!-- <div class="title">צבע הטופס:</div>
                  <verte picker="square" v-model="event.color" model="rgb"></verte> -->
					<input class="btn" type="submit" value="שמור נתונים" />
				</form>
			</div>
		</div>
		<div class="col-md-6">
			<div id="iframe_wrapper">
				<Frame
					:frame="{
						event: event.type_name,
						name: event.name,
						location: event.location,
						date: event.event_date,
						time: event.event_time,
						pic: pic_uploaded,
						note: event.note,
						rsvp_pic: invaite_uploaded,
					}"
				/>
			</div>
		</div>
		<div class="clr"></div>
	</div>
</template>
<script>
import Frame from "../components/Frame.vue";
export default {
	components: { Frame },
	data() {
		return {
			pic_uploaded: "",
			invaite_uploaded: "",
			loaded: false,
			event: {},
			pic_loaded: false,
			invaite_loaded: false,
			defualt_sms:
				", אנו מזכירים לכם כי היום, התאריך שלכם  תחול חתונתם של השמות שלכם  באולם שלכם נשמח לראותכם! ניתן להעניק מתנה באשראי לזוג דרך הלינק:",
		};
	},
	mounted() {
		this.net_api(
			{ action: "event_data/get", data: { id: this.$store.event.id } },
			(data) => {
				this.event = data.data;
				this.loaded = true;
				this.pic_uploaded = this.event.pic_parsed;
				this.invaite_uploaded = this.event.invitation_parsed;
			}
		);
	},
	methods: {
		save() {
			if (
				this.isNull(this.event.name) ||
				this.isNull(this.event.location) ||
				this.isNull(this.event.event_date)
			) {
				this.alert("נא להזין את כל השדות");
			} else {
				this.net_api(
					{ action: "event_data/save_event", data: this.event },
					() => {
						//    console.log(date);
						alert("המידע נשמר בהצלחה!");
					}
				);
			}
		},

		chooseFiles(invite = false) {
			if (!invite) document.getElementById("fileUpload").click();
			else document.getElementById("fileUpload_invite").click();
		},
		uploadFile(invite = false) {
			var self = this;

			var headers = { "Content-Type": "application/json; charset=utf-8" };

			if (!this.isNull(localStorage.getItem("user_token"))) {
				headers.Authorization = localStorage.getItem("user_token");
			}
			var url = "";
			let formData = new FormData();
			if (!invite) {
				self.pic_loaded = true;
				url = "change_main_pic";

				formData.append("file", this.$refs.file_pic.files[0]);
			} else {
				self.invaite_loaded = true;
				url = "change_invite_pic";
				formData.append("file", this.$refs.file.files[0]);
			}
			this.$http
				.post(window.api_url + url, formData, {
					/*emulateJSON:true,*/ headers: headers,
				})
				.then(
					(response) => {
						if (response.body == "error") {
							localStorage.setItem("user_token", "");
							document.location.reload();
						}
						if (!invite) {
							self.pic_loaded = false;
							self.pic_uploaded = response.body.data.pic_parsed;
						} else {
							self.invaite_loaded = false;
							self.invaite_uploaded = response.body.data.pic_parsed;
						}

						console.log(response.body);

						self.attachments.push(response.body.data);
						//alert(response.body.data)
						self.$router.go();
						//console.log(response.body);
					},
					(response) => {
						console.error(response.body);
					}
				);
		},
	},
};
</script>
<style lang="scss" scoped>
.clr{clear: both;}
.pic{height: 160px;width: 160px; background-size: cover;}
.colmd_wrap{width: 70%;margin: 0 auto;}
#forms_page{direction: rtl;text-align: right;}
#iframe_wrapper{
	&{z-index:1;height: 772px;width: 380px;padding: 20px 19px 80px;border: none;;background-image: url(../assets/iphone13frame.png);background-size: cover;}
	@media (max-width:600px) {width: 320px;height: 650px; overflow-y:hidden;padding:16px 18px 16px}
	&::before{position: absolute; top:0px; left:95px; width:145px; height:30px; border-radius: 0 0 20px 20px; background: black;z-index: 1;}
}
iframe{height: 100% !important;width: 100%;border: none;}

.field {
	&{display: flex; flex-direction: column;}
	label {text-align: start;font-size:19px;font-weight: 500;margin-top: 15px;margin-bottom: 8px;}
	input {font-size:18px;border: 0;border-radius: 5px;padding: 10px;background: #eeeeee;
	}
	.dateTime {
		&{display: flex;width:100%}
		.date { width:59%;margin-left: 2%;}
		.time {width:39%;}
	}
}
.change_pic {cursor:pointer}
.image {
	&{display: flex;flex-direction: row-reverse; justify-content: center; align-items: center;margin-top: 20px;}
	@media (max-width:600px) {flex-direction: column;}
	.btn {font-size: 14px; margin-right: 20px;}
	}
</style>