<template>
	<div>
		<md-dialog :md-active.sync="showDialog">
			<div id="form">
				<div class="title">העלאת אורחים</div>
				<form @submit.prevent="send()">
					<input
						type="text"
						placeholder="שם מלא"
						name="name"
						v-model="form.name"
						required
					/>
					<input
						type="text"
						name="phone"
						placeholder="מספר פלאפון"
						v-model="form.phone"
						required
					/>
					<input
						type="text"
						name="guests"
						placeholder="כמות מוזמנים"
						v-model="form.num_invites"
						required
					/>
					<select v-model="form.groom">
						<option value="1">{{ groom_text }}</option>
						<option value="0">{{ bride_text }}</option>
					</select>
					<select v-model="form.status">
						<option :value="0" selected>לא נכנסו ללינק</option>
						<option :value="1">מגיע</option>
						<option :value="2">לא מגיע</option>
						<option :value="3">מתלבטים</option>
					</select>
					<input type="submit" class="btn" value="העלה" />
				</form>
			</div>
		</md-dialog>
		<md-dialog :md-active.sync="delete_user_pop">
			<div id="form">
				<div class="title">הסרת אורח</div>
				<form @submit.prevent="delete_user(deleted_guest.id)">
					<h3>?בטוחים שכדאי להסיר את {{ deleted_guest.name }}</h3>
					<div @click="delete_user_pop = !delete_user_pop" class="btn">
						ביטול
					</div>
					<input type="submit" class="btn" value="הסרה" />
				</form>
			</div>
		</md-dialog>
		<md-dialog :md-active.sync="showDialog_guest">
			<div id="form">
				<div class="title">עריכת אורח</div>
				<form @submit.prevent="send_guest">
					<input
						type="text"
						placeholder="שם מלא"
						name="name"
						v-model="guest_info.name"
						required
					/>
					<input
						type="text"
						name="phone"
						placeholder="מספר פלאפון"
						v-model="guest_info.phone"
						required
					/>
					<input
						type="text"
						name="guests"
						placeholder="כמות מוזמנים"
						v-model="guest_info.num_invites"
						required
					/>
					<select v-model="guest_info.groom">
						<option value="1">{{ groom_text }}</option>
						<option value="0">{{ bride_text }}</option>
					</select>
					<select v-model="guest_info.status">
						<option :value="0">לא נכנסו ללינק</option>
						<option :value="1">מגיע</option>
						<option :value="2">לא מגיע</option>
						<option :value="3">מתלבטים</option>
					</select>
					<input type="submit" class="btn" value="שמור" />
				</form>
			</div>
		</md-dialog>
		<h1>לפניכם כמה אפשרויות:</h1>
		<div class="help">
			<div class="head">איך זה עובד?</div>
			<ul>
				<li>
					ביכולתכם לשלוח ידנית בוואצאפ את לינק החתן לכל מוזמני ה
					{{ groom_text }} וכך גם ל{{ bride_text }}, המוזמנים ימלאו את סטטוס
					הגעתם ואנחנו נעדכן את הרשימה :)
				</li>
				<li>
					באפשרותכם להעלות קובץ אקסל של רשימת המוזמנים על ידי לחיצה על כפתור
					"העלאת קובץ אקסל" - את הקובץ יש לבנות לפי הפורמט
					<a
						href="/files/%D7%A8%D7%A9%D7%99%D7%9E%D7%AA-%D7%9E%D7%95%D7%96%D7%9E%D7%A0%D7%99%D7%9D.xlsx"
						>הבא</a
					>.
				</li>
				<li>
					בלחיצה על כפתור "הורדת רשימת מוזמנים" תקבלו קובץ EXCEL של רשימת
					המוזמנים.
				</li>
				<li>בלחיצה על כפתור "הוספת מוזמן" תוכלו להוסיף מוזמן ידנית.</li>
			</ul>
		</div>
		<h1>רשימת המוזמנים:</h1>
		<div id="btn_wrapper">
			<div id="up_table">
				<a class="btn" @click="showUpload = !showUpload"
					><i class="fas fa-file-upload"></i> העלאת קובץ אקסל</a
				>
				<div class="upload_wrapper" v-if="showUpload">
					<vue-dropzone
						ref="dropzone"
						:options="dropzoneOptions"
						@vdropzone-success="onUpload"
						:useCustomSlot="true"
					>
						<div class="dropzone-custom-content">
							<h3 class="dropzone-custom-title">
								גרור לכאן את קובץ האקסל המלא!
							</h3>
							<div class="subtitle">או לחץ כאן כדי לטעון אותו מהמחשב..</div>
						</div>
					</vue-dropzone>
				</div>
			</div>
			<div id="down_table">
				<export-excel class="btn" :data="guests"
					>הורדת רשימת מוזמנים</export-excel
				>
			</div>
			<div id="cash">
				<a class="btn" @click="showDialog = true">+ הוספת מוזמן</a>
			</div>
			<div id="cash">
				<a
					class="btn"
					target="_blank"
					:href="'https://matana.app/rsvp/' + $store.event.id"
					>לינק {{ bride_text }}</a
				>
			</div>
			<div id="cash">
				<a
					class="btn"
					target="_blank"
					:href="'https://matana.app/rsvp/' + $store.event.id + '?party=1'"
					>לינק {{ groom_text }}</a
				>
			</div>
			<div id="cash" v-if="$store.event.credit == 1">
				<a
					class="btn"
					target="_blank"
					:href="'https://matana.app/mazaltov/' + $store.event.id"
					>לינק למתנה</a
				>
			</div>
		</div>
		<div id="gusets_list">
			<template v-if="!is_mobile()">
				<md-table
					v-model="searched"
					md-sort="name"
					md-sort-order="asc"
					md-card
					md-fixed-header
					v-if="table_loaded"
				>
					<md-table-toolbar>
						<div class="md-toolbar-section-start flex_item">
							<h2 class="md-title">מוזמנים</h2>
						</div>
						<md-field md-clearable class="md-toolbar-section-end flex_item">
							<md-input
								placeholder="חפש מוזמנים לפי שם..."
								v-model="search"
								@input="searchOnTable"
							/>
						</md-field>
					</md-table-toolbar>
					<md-table-empty-state md-label="לא נמצא אורח בשם זה">
						<md-button class="md-primary md-raised" @click="showDialog = true"
							>הוספת מוזמן</md-button
						>
					</md-table-empty-state>
					<md-table-row slot="md-table-row" slot-scope="{ item }">
						<md-table-cell md-label="שם מלא" md-sort-by="name">{{
							item.name
						}}</md-table-cell>
						<!-- <md-table-cell md-label="אי-מייל" md-sort-by="email">{{ item.email }}</md-table-cell> -->
						<md-table-cell md-label="מספר נייד" md-sort-by="phone">{{
							item.phone
						}}</md-table-cell>
						<md-table-cell md-label="מספר מוזמנים" md-sort-by="num_invites">{{
							item.num_invites
						}}</md-table-cell>
						<md-table-cell md-label="אישור הגעה" md-sort-by="status"
							><input type="checkbox" v-if="item.editLine" />
							{{
								item.status == 1
									? "כן"
									: item.status == 3
									? "מתלבט"
									: item.status == 0
									? "לא נכנסו ללינק"
									: "לא"
							}}
						</md-table-cell>

						<md-table-cell md-label="צד" md-sort-by="groom"
							>{{ item.groom == "1" ? groom_text : bride_text }}
						</md-table-cell>
						<md-table-cell md-label="עריכה" md-sort-by="title"
							><a @click="edit_guest(item.id)" class="clickable"
								>עריכה</a
							></md-table-cell
						>
						<md-table-cell md-label="מחיקה" md-sort-by="title"
							><a @click="delete_guest_pop(item)" class="clickable"
								>מחיקה</a
							></md-table-cell
						>
						<!-- <md-table-cell md-label="אישור הגעה" md-sort-by="title"><input type="checkbox" v-if="item.editLine">{{ (item.status == '1' ? 'כן' : (item.status == '-1' ? 'מתלבט' : "לא") ) }} <a>שמירה</a> <a v-show="!item.editLine" @click="item.editLine=true">(עריכה)</a></md-table-cell> -->
					</md-table-row>
				</md-table>
			</template>

			<template v-if="is_mobile()">
				<md-table
					v-model="searched"
					md-sort="name"
					md-sort-order="asc"
					md-card
					md-fixed-header
					v-if="table_loaded"
				>
					<md-table-toolbar>
						<div class="md-toolbar-section-start">
							<h2 class="md-title">מוזמנים</h2>
						</div>
						<md-field md-clearable class="md-toolbar-section-end">
							<md-input
								placeholder="חפש אורח לפי שם  ..."
								v-model="search"
								@input="searchOnTable"
							/>
						</md-field>
					</md-table-toolbar>

					<md-table-empty-state md-label="לא נמצא אורח בשם זה">
						<md-button class="md-primary md-raised" @click="showDialog = true"
							>הוספת מוזמן</md-button
						>
					</md-table-empty-state>

					<md-table-row
						slot="md-table-row"
						slot-scope="{ item }"
						@click="item.isShowing = !item.isShowing"
					>
						<i class="fas fa-plus"></i>
						<md-table-cell md-label="שם מלא" md-sort-by="name"
							>{{ item.name }}
							<div class="box" v-show="item.isShowing">
								<p md-sort-by="phone">טלפון: {{ item.phone }}</p>
								<p md-sort-by="groom">
									צד: {{ item.groom == "1" ? groom_text : bride_text }}
								</p>
								<div class="wrapper">
									<div md-label="עריכה" md-sort-by="title">
										<a @click="edit_guest(item.id)"
											><i class="far fa-edit"></i
										></a>
									</div>
									<div md-label="מחיקה" md-sort-by="title">
										<a @click="delete_guest_pop(item)"
											><i class="far fa-trash-alt"></i
										></a>
									</div>
								</div>
							</div>
						</md-table-cell>
						<md-table-cell md-label="אישור הגעה" md-sort-by="status">
							{{
								item.status == 1
									? "כן"
									: item.status == 3
									? "מתלבט"
									: item.status == 0
									? "לא נכנסו ללינק"
									: "לא"
							}}
						</md-table-cell>
						<md-table-cell md-label="מספר מוזמנים" md-sort-by="num_invites">{{
							item.num_invites
						}}</md-table-cell>
					</md-table-row>
				</md-table>
			</template>
			<div class="prog_wrapper" v-if="!table_loaded">
				<md-progress-spinner
					:md-diameter="100"
					:md-stroke="10"
					md-mode="indeterminate"
				></md-progress-spinner>
			</div>
		</div>
	</div>
</template>
<script>
const toLower = (text) => {
	return text.toString().toLowerCase();
};

const searchByName = (items, term) => {
	if (term) {
		return items.filter((item) => toLower(item.name).includes(toLower(term)));
	}

	return items;
};
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
	components: {
		vueDropzone: vue2Dropzone,
	},
	data() {
		return {
			deleted_guest: {},
			delete_user_pop: false,
			showUpload: false,
			dropzoneOptions: {
				url: "https://www.matana.app/api/upload",
				thumbnailWidth: 150,
				uploadMultiple: false,
				maxFiles: 1,
				//acceptedFiles: 'xls,xlsx,csv',
				//maxFilesize: 0.5,
				headers: { event_id: this.$store.event.id },
			},
			showDialog_guest: false,
			table_loaded: false,
			showDialog: false,
			bride_text: "כלה",
			groom_text: "חתן",
			form: {
				name: "",
				phone: "",
				num_invites: "",
				status: "",
				side: null,
			},
			guest_info: {},

			guests: [],
			search: null,
			searched: [],
		};
	},
	methods: {
		searchOnTable() {
			this.searched = searchByName(this.guests, this.search);
		},
		send_rsvp_sms() {
			this.net_api(
				{
					action: "guests/send_rsvp_sms",
					data: { event_id: this.$store.event.id },
				},
				() => {
					this.alert("הזמנות נשלחו");
				}
			);
		},
		delete_guest_pop(item) {
			this.deleted_guest = item;
			this.delete_user_pop = true;
		},
		delete_user(id) {
			this.net_api({ action: "guests/delete", data: { id: id } }, () => {
				this.delete_user_pop = false;
				this.alert("אורח נמחק בהצלחה!");
				this.loadTable();
			});
		},
		edit_guest(id) {
			this.net_api({ action: "guests/get", data: { id: id } }, (data) => {
				this.guest_info = data.data;
				this.showDialog_guest = true;
			});
		},
		send_guest() {
			this.net_api(
				{ action: "guests/edit_guest", data: this.guest_info },
				(data) => {
					if (data.data < 5) {
						this.alert("חסר לכם את אחד מהשדות");
					}
					this.loadTable();
					this.showDialog_guest = false;
					// this.alert("השינויים נשמרו")
				}
			);
		},
		onUpload() {
			this.$refs.dropzone.removeAllFiles();
			this.showUpload = false;
			this.alert("מוזמנים נוספו לרשימה");
			this.loadTable();
		},
		loadTable() {
			this.table_loaded = false;
			this.net_api(
				{ action: "guests/get_all", data: { event_id: this.$store.event.id } },
				(data) => {
					for (let i in data.data) {
						data.data[i].isShowing = false;
					}

					this.guests = data.data;
					setTimeout(() => {
						this.searched = this.guests;
						this.table_loaded = true;
					}, 500);
				}
			);
		},
		send() {
			if (this.form.name && this.form.phone && this.form.num_invites) {
				const formData = {
					name: this.form.name,
					phone: this.form.phone,
					num_invites: this.form.num_invites,
					groom: this.form.groom,
					status: this.form.status,
					event_id: this.$store.event.id,
				};
				console.log(formData);
				this.net_api({ action: "guests/add_guest", data: formData }, (data) => {
					if (data.data) {
						this.showDialog = false;
						this.alert("אורח/ת התווסף בהצלחה");

						this.loadTable();
					}
				});
				this.form.name = "";
				this.form.phone = "";
				this.form.num_invites = "";
				(this.form.side = ""), (this.form.status = "");
			}
		},
	},
	mounted() {
		if (this.$store.event.party_1_text != "") {
			this.bride_text = this.$store.event.party_1_text;
		}
		if (this.$store.event.party_2_text != "") {
			this.groom_text = this.$store.event.party_2_text;
		}

		this.loadTable();
	},
};
</script>
<style lang="scss" scoped>
.flex_item{max-width: 250px;}
#btn_wrapper{
	&{display: flex;flex-wrap: wrap;justify-content: right;gap: 10px;}
	@media (max-width:600px) {gap:0px 10px}
}
.btn{
	&{text-align: center;}
	@media (max-width:600px) {font-size: 17px;}
}
.prog_wrapper{margin: 0 auto;text-align: center;}
#form{ padding: 30px;}
form{ display: flex;flex-direction: column; flex-wrap: wrap;align-content: center;}
form input[type=text] { width: 100%;padding: 12px 20px;margin: 8px 0;box-sizing: border-box;text-align: right;}
.title{text-align: center;font-size: 25px;font-weight: 600;margin-bottom: 15px;}
.md-title{text-align: center;}
.upload_wrapper { width: 300px; margin: 0 auto;}

#gusets_list {
	&{padding: 30px 0 0 20px;}
	@media (max-width:600px){padding: 0; padding-bottom: 50px; }
}
select{    height: 30px;font-size: 14px;font-weight: 600;text-align: center; outline: none;  margin: 10px 0;}
.box{
    .wrapper{display:flex;
        .fa-edit{color:#000; margin-left: 10px; font-size: 18px;}
        .fa-trash-alt{color:#000; font-size: 18px;}
    }
}
@media(max-width:600px){
    .md-table-row{position: relative;
        .fa-plus{font-size: 11px; position: absolute; top: 18px; right: 4px;}
    }
	h1 {padding-top: 0 !important;}
}
</style>