<template v-if="loaded" id="forms_page">
	<div class="centered">
		<div class="col-md-6">
			<div class="colmd_wrap">
				<h1>פרטי האירוע:</h1>
				<form @submit.prevent="save()">
					<div class="field">
						<label>שם הבנק</label>
						<input type="text" v-model="bank.bank_name" />
					</div>
					<div class="field">
						<label>מספר סניף</label>
						<input type="text" v-model="bank.branch" />
					</div>
					<div class="field">
						<label>מספר חשבון</label>
						<input type="text" v-model="bank.account_num" />
					</div>
					<div class="field">
						<label>שם בעל החשבון</label>
						<input type="text" v-model="bank.owner_name" />
					</div>
					<input class="btn" type="submit" value="שמור נתונים" />
				</form>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			loaded: false,
			bank: {},
		};
	},
	mounted() {
		console.log(this.$store.event.id);
		this.net_api(
			{ action: "bank_data/get", data: { event_id: this.$store.event.id } },
			(data) => {
				if (data.data !== null) this.bank = data.data;
			}
		);
		this.net_api(
			{ action: "event_data/get", data: { id: this.$store.event.id } },
			(data) => {
				this.bank.event_id = data.data.id;
				this.loaded = true;
			}
		);
	},
	methods: {
		save() {
			if (
				this.isNull(this.bank.bank_name) ||
				this.isNull(this.bank.branch) ||
				this.isNull(this.bank.account_num) ||
				this.isNull(this.bank.owner_name)
			) {
				this.alert("נא להזין את כל השדות");
			} else {
				this.net_api({ action: "bank_data/save_bank", data: this.bank }, () => {
					alert("המידע נשמר בהצלחה!");
				});
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.clr{clear: both;}
.colmd_wrap{width: 70%;margin: 0 auto;}
#forms_page{direction: rtl;text-align: right;}

.field {
	&{display: flex; flex-direction: column;}
	label {text-align: start;font-size:19px;font-weight: 500;margin-top: 15px;margin-bottom: 8px;}
	input {font-size:18px;border: 0;border-radius: 5px;padding: 10px;background: #eeeeee;}
}
</style>