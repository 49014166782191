<template>
	<div id="app" :class="'rtl ' + (show_side_menu ? 'menu_open' : '')">
		<main
			id="page-wrap"
			v-if="loaded"
			:class="
				(show_login ? 'no_header' : '') +
				' ' +
				(is_notification_open ? 'show_notifications' : '')
			"
		>
			<div id="hero">
				<router-link to="/"><div class="logo_matana"></div></router-link>
			</div>

			<div id="bar_wrapper" v-if="!show_login">
				<Bar v-if="!is_mobile()" />
				<BarMobile v-if="is_mobile()" />
			</div>

			<LoginPage
				v-if="show_login"
				@goToSignup="
					goto_signup = true;
					goto_login = false;
				"
				@closePopup="
					close_popup = true;
					show_login = true;
					goto_login = false;
				"
			/>

			<div id="page_content" v-if="!show_login">
				<template>
					<router-view :key="$route.path"></router-view>
				</template>
			</div>
		</main>
		<link
			rel="stylesheet"
			href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons"
		/>
	</div>
</template>

<script>
import LoginPage from "./views/login.vue";
import Bar from "./components/bar.vue";
import BarMobile from "./components/barMobile.vue";
import { mixin as clickaway } from "vue-clickaway";

export default {
	name: "app",
	mixins: [clickaway],
	components: {
		LoginPage,
		Bar,
		BarMobile,
	},

	data() {
		return {
			open_chats: [],
			user_data: {},
			chat: {},
			notifications: "",
			close_popup: false,
			user_parse: {},
			show_notifications: false,
			show_side_menu: false,
			show_login: false,
			force_edit: false,
			goto_signup: false,
			goto_login: false,
			shake: false,
			loaded: false,
			show_user_popup: false,
			is_notification_open: false,
			bar_opened: true,
			menu: [],
		};
	},
	methods: {
		awayNot: function () {
			this.show_notifications = false;
		},
		logout() {
			localStorage.removeItem("user_token");
			document.location = "/";
		},
		close_chat(id) {
			var index = this.open_chats.indexOf(id);
			if (index !== -1) {
				this.open_chats.splice(index, 1);
			}
		},

		parse_user_channel(msg) {
			var data = JSON.parse(msg.message);
			// console.log(data);

			switch (data.mtype) {
				case "chat_msg": {
					let exists = false;
					for (let i in this.$refs.chatbox) {
						let chatbox = this.$refs.chatbox[i];
						if (chatbox.user_id == data.data.uid_from) {
							chatbox.pushMessage({
								author: data.data.uid_from,
								type: "text",
								data: { text: data.data.msg },
							});
							exists = true;
							break;
						}
					}

					if (!exists) {
						if (this.open_chats.indexOf(data.data.uid_from) === -1)
							this.open_chats.push(data.data.uid_from);
					}

					// TODO: Update When your on Inbox as well - Avichay

					break;
				}
				case "add_notifay": {
					this.notifications++;
					break;
				}
			}
		},

		init_general(data) {
			var self = this;

			self.user_data = data.user;
			self.$store.stats = data.event.stats;
			self.$store.user = data.user;
			self.$store.event = data.event;

			self.menu = data.menu;

			if (!(self.user_data.id > 0)) {
				self.show_login = true;
			} else {
				//  console.log(self.$store.user)
				//  if(!self.isNull(self.$store.user.job))
				//  {
				//    self.alert(1)
				//  }
			}
		},
	},
	mounted() {
		document.addEventListener("gesturestart", function (e) {
			e.preventDefault();
		});

		this.$store.inapp = !this.isNull(this.$route.query.inapp);

		if (!this.isNull(this.$route.query.token)) {
			localStorage.setItem("user_token", this.$route.query.token);
		}
		window.appVue = this;

		if (!this.userToken) {
			this.show_login = true;
		}
		// console.log(this.$store.user.id)
		// categories

		this.net_api({ action: "general" }, (data) => {
			this.init_general(data.data);
			this.loaded = true;
		});
	},
};
</script>
<style lang="scss" scoped>
.chat_num{display: block;
    width: 15px;
    height: 15px;
    line-height: 16px;
    background: red;
    color: #fff;
    font-size: 13px;
    text-align: center;
    position: absolute;
    right: -8px;
    top: -2px;
    border-radius: 100%;}
#env_icon{position: absolute;left: 60px;font-size: 24px;}
#user_popup_notifications{position: absolute;width: 100%;left: 0px;}
#header_mobile{
  #notifications::after{display: none;}
}
#notification_btn_head{position: absolute;left: 10px;top: 13px;}
#notification_btn_head{
   span {display: block; width: 15px;height: 15px; line-height: 16px;; background: red; color: #fff; font-size: 13px; text-align: center; position: absolute; right: -8px; top: -2px; border-radius: 100%; }
}
#hero{background:#0a1130; height: 80px;
  .logo_matana{background-image:url(./assets/logo_white_hatuna.png); background-size: contain; background-repeat: no-repeat; background-position: center; width:100%; height: 50px; position: absolute; top: 15px;}
}

@media only screen and (max-width: 600px) {  
  #bar_wrapper{border-bottom-left-radius:0px;}
} 
  

#user_drop{
  .user_icon{margin: 0 auto;}
}
.disabeld_scroll{
  &{position: fixed;height: 100%;width: 100%;top: 0;z-index: 1;background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 35%, rgba(0, 0, 0, 0.5) 100%);}

  h3{font-size: 30px;
    width: 50%;
    margin: 0 auto;
    line-height: 35px;
    font-weight: 600;
    margin-bottom: 20px;}
  .btn:nth-child(2){    margin: 0 10px;background-color: #000 !important;color: #fff;border-radius: 0;}
  .btn:nth-child(3){  margin: 0 10px;  background-color: #fff !important;  color: #000 !important;  border-radius: 0;  width: 130px;}
  .btn{width: 200px !important;padding: 0 40px !important;border-radius: 3px !important;}
}

</style>