<template>
	<div id="bar_mobile" :class="open_menu ? 'open' : ''">
		<div class="hamburger-menu" @click="open_menu = !open_menu">
			<div id="menu__toggle"></div>
			<label class="menu__btn" for="menu__toggle">
				<span></span>
			</label>

			<ul class="menu__box">
				<h1>שלום {{ $store.user.display_name }}</h1>
				<li
					v-for="item in menu"
					:key="item.name"
					:class="{ active: $route.path == item.link }"
				>
					<router-link
						class="menu__item"
						:to="item.link"
						@click="open_menu = false"
						>{{ item.name }}</router-link
					>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			open_menu: false,
			menu: [],
		};
	},
	methods: {},
	mounted() {
		if (this.$store.event.max_sms == 0) {
			this.menu = [
				{ name: "דף הבית", link: "/" },
				{ name: "פרטי האירוע", link: "/forms" },
				{ name: "קניית הודעות", link: "/sms_plans" },
				{ name: "אישורי הגעה", link: "/rsvp" },
			];
		} else {
			this.menu = [
				{ name: "דף הבית", link: "/" },
				{ name: "פרטי האירוע", link: "/forms" },
				{ name: "שליחת הודעות", link: "/sms" },
				{ name: "קניית הודעות", link: "/sms_plans" },
				{ name: "אישורי הגעה", link: "/rsvp" },
			];
		}
		if (this.$store.event.credit == 1) {
			this.menu.push({ name: "ברכות ותשלומים", link: "/payments" });
			this.menu.push({ name: "פרטי חשבון בנק", link: "/bank" });
		}
	},
};
</script>
<style lang="scss" scoped>
    #menu__toggle {
  opacity: 0;
}

.open #menu__toggle ~ .menu__btn  span {
  transform: rotate(45deg);
}
.open #menu__toggle ~ .menu__btn  span::before {
  top: 0;
  transform: rotate(0);
}
.open #menu__toggle ~ .menu__btn  span::after {
  top: 0;
  transform: rotate(90deg);
}
.open #menu__toggle ~ .menu__box {
  visibility: visible;
  right: 0;
  text-align: right;
}

.menu__btn {
  display: flex;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;

  width: 26px;
  height: 26px;

  cursor: pointer;
  z-index: 99999;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #ffff;

  transition-duration: .25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}

.menu__box {
  display: block;
  position: fixed;
  visibility: hidden;
  top: 0;
  right: -100%;

  width: 250px;
  height: 100%;

  margin: 0;
  padding: 80px 0;

  list-style: none;

  background-color: #0a1130;
  box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);

  transition-duration: .25s;

  z-index: 9999;
}

.menu__box h1{padding: 0 20px; color:#fff;}

.menu__item {
  display: block;
  padding: 12px 24px;

  color: #fff;

  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;

  text-decoration: none;

  transition-duration: .25s;
}
.menu__item:hover {
  background-color: #fff; color:#fff;
}

.md-theme-default a:not(.md-button){color:#fff; text-decoration: none;}

</style>