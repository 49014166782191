import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import rsvp from "../views/rsvp.vue";
import sms from "../views/sms.vue";
import payments from "../views/payments.vue";
import seats from "../views/seats.vue";
import forms from "../views/forms.vue";
import SmsPlans from "../views/SmsPlans.vue";
import bank from "../views/Bank.vue";

Vue.use(VueRouter);

const routes = [
	{ path: "/", name: "Home", component: Home },
	{ path: "/payments", name: "payments", component: payments },
	{ path: "/sms", name: "sms", component: sms },
	{ path: "/sms_plans", name: "sms_plans", component: SmsPlans },
	{ path: "/rsvp", name: "rsvp", component: rsvp },
	{ path: "/forms", name: "forms", component: forms },
	{ path: "/seats", name: "seats", component: seats },
	{ path: "/bank", name: "bank", component: bank },
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
