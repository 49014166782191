<template>
    <div>
    <!-- <a @click="send_credit_sms" class="btn">שליחת SMS לאישור הגעה לכל המוזמנים</a> -->
    <p>סה"כ שילמו {{total}}</p>
      <md-switch v-model="is_paid"  @change="loadTable">שולם</md-switch>
        <div id="gusets_list">
            <md-table v-model="guests" md-sort="name" md-sort-order="asc" md-card md-fixed-header v-if="table_loaded">
            <md-table-toolbar>
                <h1 class="md-title">מוזמנים</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="שם מלא" md-sort-by="name">{{ item.name }}</md-table-cell>
                <md-table-cell md-label="מספר נייד" md-sort-by="phone">{{ item.phone }}</md-table-cell>
                <md-table-cell md-label="ברכה" md-sort-by="bless">{{ item.bless }}</md-table-cell>
                <md-table-cell md-label="סכום" md-sort-by="amount">{{ item.amount }}</md-table-cell>
                <md-table-cell md-label="שולם" md-sort-by="is_paid">{{ (item.is_paid == '1' ? 'כן' : 'לא' ) }} </md-table-cell>
            </md-table-row>
            </md-table>
            <div class="prog_wrapper" v-else>
            <md-progress-spinner  :md-diameter="100" :md-stroke="10" md-mode="indeterminate"></md-progress-spinner>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {
       
    },
    data(){
        return{
            showUpload: false,
            dropzoneOptions: {
                url: 'https://www.hatuna.me/api/upload',
                thumbnailWidth: 150,
                uploadMultiple: false,
                maxFiles:1,
                //acceptedFiles: 'xls,xlsx,csv',
                //maxFilesize: 0.5,
                headers: { "event_id": this.$store.event.id }
            },
            showDialog_guest:false,
            table_loaded:false,
            total:'',
            is_paid:true,
            showDialog:false,
            form:{
                name:'',
                phone:'',
                num_invites:'',
            },
            guest_info:{},
            guests: []
        }
    },
    methods:{
         send_credit_sms(){
            this.net_api({ action: 'guests/send_credit_sms', data:{event_id: this.$store.event.id} }, () => {
                this.alert('הזמנות נשלחו');
            });
        },
        loadTable(){
            this.table_loaded = false
            console.log(this.$store.event)
            this.net_api({ action: 'guests/get_all_payments', data:{event_id: this.$store.event.id,is_paid:this.is_paid} }, (data) => {

         
                this.guests = data.data.list;
                this.total = data.data.total;
                setTimeout(() => {
                     this.table_loaded = true
                }, 500);
                
            });
        },
 
    },
    mounted(){
        this.loadTable();
    }
}
</script>
<style scoped lang="scss">
.prog_wrapper{margin: 0 auto;text-align: center;}
#form{ padding: 30px;}
form{ display: flex;flex-direction: column; flex-wrap: wrap;align-content: center;}
form input[type=text] { width: 100%;padding: 12px 20px;margin: 8px 0;box-sizing: border-box;text-align: right;}
.title{text-align: center;font-size: 25px;font-weight: 600;margin-bottom: 15px;}

.upload_wrapper { width: 300px; margin: 0 auto;}

#gusets_list { padding: 30px 100px;}
select{    height: 30px;font-size: 14px;font-weight: 600;text-align: center; outline: none;  margin: 10px 0;}
@media(max-width:600px){
    .md-table-row{position: relative;
        .fa-plus{font-size: 11px; position: absolute; top: 18px; right: 4px;}
    }
}
</style>