<template>
	<div>
		<h1>קניית הודעות SMS</h1>
		<div class="help">
			<div class="head">יש לבחור כמות הודעות לרכישה</div>
			<div id="select">
				<select v-model="sms_count">
					<option value="100" class="item">100</option>
					<option value="200" class="item">200</option>
					<option value="300" class="item">300</option>
					<option value="400" class="item">400</option>
					<option value="500" class="item">500</option>
					<option value="600" class="item">600</option>
					<option value="700" class="item">700</option>
					<option value="800" class="item">800</option>
					<option value="800" class="item">800</option>
					<option value="900" class="item">900</option>
					<option value="1000" class="item">1000</option>
				</select>
				<div>
					<span>מחיר סמס: </span>
					<span>{{ sms_price * 100 }} אגורות לאחד</span>
				</div>
				<div>כמות הודעות שנבחרו לרכישה: {{ sms_count }}</div>
				<div>מחיר: ₪{{ buy_price }}</div>
			</div>
			<button class="btn" @click="buy_sms">לאישור קנייה</button>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";

export default {
	data() {
		return {
			sms_count: 100,
			sms_price: 0.08,
		};
	},
	computed: {
		buy_price() {
			return Math.floor(this.sms_count * this.sms_price);
		},
	},
	methods: {
		buy_sms() {
			const swalWithBootstrapButtons = Swal.mixin({
				customClass: {
					confirmButton: "btn-success",
					cancelButton: "btn-danger",
				},
				buttonsStyling: true,
			});

			swalWithBootstrapButtons
				.fire({
					title: "SMS רכישת",
					text:
						"האם אתה בטוח שברצונך לרכוש עוד " +
						this.sms_count +
						" במחיר של " +
						this.sms_price +
						"?",

					// icon: "warning",
					showCancelButton: true,
					confirmButtonText: "רכישה",
					cancelButtonText: "ביטול",
					reverseButtons: true,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.net_api(
							{
								action: "purchase_sms",
								data: {
									event_id: this.$store.event.id,
									user_id: this.$store.user.id,
									sms_count: this.sms_count,
								},
							},
							(data) => {
								if (data.data === true) {
									swalWithBootstrapButtons
										.fire("נרכשו SMS", "!תודה על רכישתך", "success")
										.then(() => {
											this.$store.event.max_sms =
												Number(this.$store.event.max_sms) +
												Number(this.sms_count);
											location.reload();
										});
								} else {
									swalWithBootstrapButtons.fire("שגיאה", data.data, "error");
								}
							}
						);
					}
				});
		},
	},
};
</script>

<style lang="scss" scoped>
	#select{display: block;font-size: 20px;margin: 10px 30px ;}
	.item{font-size: 18px;line-height: 24px;}
	select {width:20%;font-size:20px}
</style>