<template>
	<div id="rsvp_page">
		<div class="centered">
			<div class="top_topper">
				<div class="topper"></div>
			</div>
			<div
				class="pic"
				:style="
					'background-image:linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 35%, rgba(0,0,0,0.3) 100%),url(' +
					frame.pic +
					')'
				"
			>
				<div class="info">
					<div class="name" v-if="frame.name">
						{{ frame.name }}
					</div>
					<div class="date" v-if="frame.date">בתאריך: {{ fromatDate }}</div>
					<div class="time" v-if="frame.date">
						{{ frame.time ? "בשעה: " + frame.time : "" }}
					</div>
					<div v-if="frame.location">כתובת: {{ frame.location }}</div>
					<div class="note">{{ frame.note }}</div>
				</div>
			</div>
			<form id="form" @submit.prevent="">
				<a v-if="frame.rsvp_pic" id="invitation">צפייה בהזמנה</a>

				<div class="wrapper">
					<div class="head">מה השם?</div>
					<input
						type="text"
						name="name"
						required
						autocomplete="off"
						placeholder="לדוגמה: דוד ורונה כהן "
					/>
				</div>
				<div class="wrapper">
					<div class="head">מספר נייד</div>
					<input type="text" name="phone" required autocomplete="off" />
				</div>
				<div class="wrapper">
					<div class="head">כמה תהיו?</div>
					<div class="guests">
						<div id="plus" class="clickable" @click="add_num()">+</div>
						<input
							type="number"
							name="gustes"
							min="1"
							id="gustes"
							v-model="form.gustes"
						/>
						<div id="minus" class="clickable" @click="reduce_num()">-</div>
					</div>
				</div>
				<div class="status">
					<div class="head">אשרו הגעה</div>
					<div class="options">
						<div
							:class="'circle clickable ' + (form.status == 2 ? 'active' : '')"
							@click="form.status = 2"
						>
							<i class="fas fa-times"></i>
							<div class="">לא נגיע</div>
						</div>
						<div
							:class="'circle clickable ' + (form.status == 1 ? 'active' : '')"
							@click="form.status = 1"
						>
							<i class="fas fa-check"></i>
							<div class="">מגיעים!</div>
						</div>
						<div
							:class="'circle clickable ' + (form.status == 3 ? 'active' : '')"
							@click="form.status = 3"
						>
							<i class="fas fa-question"></i>
							<div class="">מתלבטים</div>
						</div>
					</div>
				</div>
				<input type="submit" class="btn" value="שלח" />
			</form>
		</div>
	</div>
</template>

<script>
import moment from "moment";

export default {
	props: ["frame"],
	watch: {
		frame() {
			this.fromatDate = moment(this.frame.date).format("DD/MM/YYYY");
		},
	},
	data() {
		return {
			fromatDate: this.frame.date,
			invaite: false,
			form: {
				phone: "",
				status: 1,
				gustes: 1,
			},
		};
	},
	methods: {
		reduce_num: function () {
			if (this.form.gustes >= 2) {
				this.form.gustes--;
			}
		},
		add_num: function () {
			if (this.form.gustes < 15) {
				this.form.gustes++;
			}
		},
	},
};
</script>

<style lang="scss">
#rsvp_page{
    &{overflow-x: hidden;height:772px;width:338px !important;scrollbar-width: none;margin-right: 0 !important;top: 0; z-index: 1;border-radius: 40px;}
    @media (max-width: 600px) {height:617px; width:320px;top: -5px;margin-right: -2px !important;border-radius: 35px;}
	&::-webkit-scrollbar {display: none;}
	.payment_wrapper{display: flex;justify-content: center;gap:20px;
		.wrap{    align-items: center;gap: 10px;display: flex}
		// #bit_logo{    height: 50px;background-position: center;width: 50px;;background-size: contain;background-repeat: no-repeat;background-image: url(../images/Bit_logo.svg)}
	}
	.invitation{min-height: 50vh;background-size: contain;min-width: 350px;; height: auto; background-position: center;background-repeat: no-repeat;max-width:100%}
	.date{margin-top: 8px;font-size: 17px}
	#thanks{font-size: 20px;text-align: center;font-weight: 700;font-family: 'Heebo';color: #7da6c3}
	.wrapper{margin-bottom: 30px}
	input{max-width: 70%}
	input[type=submit]{margin-top: 20px;background: #7da6c3}
	.head{    font-size: 18px;font-family: 'Heebo'; font-weight: 700; margin: 5px 0 0;}
	input[type=number]{width: 80px;text-align: center;background: #f1f1f3;border: none;border-radius: 8px;height: 40px;font-size: 18px;padding: 0 15px;}
	#plus{width: 40px;    display: inline-block;    background: #7da6c3;   height: 40px;text-align: center; vertical-align: middle;  border-radius: 100%;line-height: 35px;font-weight: 800; font-size: 22px; margin: 0 15px;color: #fff}
	#minus{width: 40px;    display: inline-block;    background: #7da6c3;   height: 40px;text-align: center; vertical-align: middle;  border-radius: 100%;line-height: 35px;font-weight: 800; font-size: 22px; margin: 0 15px;color: #fff}
	i{    font-size: 20px;   padding-top: 7px}
	.status {display: flex;flex-direction: column;}
    .options {display: flex; justify-content: center;padding-top: 10px;}
    .circle{display: flex; flex-direction: column; justify-content: center;align-items: center;font-size: 16px;height: 80px; width: 80px;
        border-radius: 100%;border: 2px solid #7da6c3;margin: 0 10px;color: #7da6c3;padding:5px}
	.circle.active{color: #fff;background: #7da6c3;box-shadow: 0 6px 24px 4px rgba(0, 0 ,0 , 0.08);padding:5px}
	.circle input{display: none}
	#form{padding: 35px 0 0;text-align: center;position: relative}
	.time_date{display: inline-block;color: #fff;font-weight: 400;font-size: 18px;font-family: 'Heebo'}
	.time_date label{font-weight: 400;font-size: 14px}
	&{  width: 500px;max-width: 100%;margin: 0 auto}
	.pic{
        &{position: relative;height: 200px; background-size: cover; background-position: center;   text-align: center;   color: #fff; font-weight: 600; font-family: 'Heebo';align-items: center;display: flex;justify-content: center;position: relative;}
        .note {position: absolute;bottom:5px; right:0; padding: 0 15px}
    }
	.name{font-size: 26px;margin-bottom: 20px}
    .guests {
        &{display: flex; justify-content: center;}
        #plus,#minus {display: flex;justify-content: center; align-items: center;}
    }
}
#form{
    .frmSbmBtn{background:#dd1a7b;;color: #fff ;width: fit-content;padding: 10px 40px;font-size: 25px;line-height: 35px;height: auto;font-family: 'Open Sans', sans-serif;font-weight: 600;font-weight: 600}
    select { height: 40px; outline: 0; border: 0; box-shadow: none; padding-right: 100px; color: #000;border-radius: 8px; background-color:#f1f1f3; background-image: none; cursor: pointer; }
	label{    display: block;margin: 0px;width: auto;font-weight: bold;}
	input[type=text]{background: #f1f1f3;border: none; border-radius: 8px; height: 40px; font-size: 18px; padding: 0 15px;  width: 100%;}
	textarea{background: #f1f1f3;border: none; border-radius: 8px; height: 80px; font-size: 18px; padding: 0 15px;  width: 100%;max-width: 70%;resize: none}
	input[type=checkbox]{ height: auto;}
	
}
.clickable {cursor: pointer;}

#invitation{background: #7da6c3 ;color: #fff;
 display: block; width: 50px; height: 50px; border-radius: 100%; text-decoration: none; font-size: 13px; font-weight: 700; line-height: 1;
 text-align: center;cursor: pointer;position: absolute;left: 5px;top: 5px;padding-top: 10px;}
 
.topper {
	&{position: absolute; top:0px; left:95px; width:145px; height:30px; border-radius: 0 0 20px 20px; background: black;z-index: 1;}
	@media (max-width:600px) {display: none;}
}
</style>