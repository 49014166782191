<template>
	<div id="bar">
		<h1>היי {{ $store.user.display_name }}</h1>
		<h3 class="bar_title" v-if="!is_mobile()">האזור האישי שלי</h3>
		<ul>
			<li
				v-for="item in menu"
				:key="item.name"
				:class="{ active: $route.path == item.link }"
				@click="$emit('closeBar')"
			>
				<router-link :to="item.link">{{ item.name }}</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			menu: [],
		};
	},
	mounted() {
		if (this.$store.event.max_sms == 0) {
			this.menu = [
				{ name: "דף הבית", link: "/" },
				{ name: "פרטי האירוע", link: "/forms" },
				{ name: "קניית הודעות", link: "/sms_plans" },
				{ name: "אישורי הגעה", link: "/rsvp" },
			];
		} else {
			this.menu = [
				{ name: "דף הבית", link: "/" },
				{ name: "פרטי האירוע", link: "/forms" },
				{ name: "שליחת הודעות", link: "/sms" },
				{ name: "קניית הודעות", link: "/sms_plans" },
				{ name: "אישורי הגעה", link: "/rsvp" },
			];
		}
		if (this.$store.event.credit == 1) {
			this.menu.push({ name: "ברכות ותשלומים", link: "/payments" });
			this.menu.push({ name: "פרטי חשבון בנק", link: "/bank" });
		}
	},
};
</script>

<style lang="scss" scoped>
#bar {
    &{ padding-left:0px; text-align: right;
        @media (max-width:600px){  position: absolute; top: 70px; left: 0px; right: 0px; z-index: 999; background:#0a1130; }
    }
    ul { 
        &{padding: 0;text-align: right;}
        li { display: block; border-top-right-radius: 100px; border-bottom-right-radius: 100px; position: relative;
            a { padding: 25px 25px 25px 0; font-size: 30px; font-weight: bold; display: block; color: #fff; 
                @media (max-width:600px){  font-size: 18px; }
            
            }

            
            a::after, a::before { content: ""; transition: border-radius 0.2s ease; }


            &.active { background: #fff;
                a { color: #0a1130;}

                &::after, &::before { content: ""; position: absolute; left: 0px; height: 40px; width:40px; background: #fff; z-index: 0;  }
                &::before { top: -40px; }
                &::after { bottom: -40px; }

                a::after, a::before { content: ""; position: absolute; left: 0px; height: 40px; width:40px; background: #0a1130; z-index: 1;  }
                a::before { top: -40px; border-bottom-left-radius: 40px; }
                a::after { bottom: -40px; border-top-left-radius: 40px; }
            }

        }
    }
}
</style>