import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueResource from 'vue-resource'
Vue.use(VueResource);
/*
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
*/
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)

import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);
import excel from 'vue-excel-export'
 
Vue.use(excel)


import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert, { reverseButtons: true });

Vue.config.productionTip = false


import './app.scss' 
import './general.scss' 

window.api_url = 'https://www.matana.app/api/'; 
window.is_production = false;

if (window.location.href.indexOf('http://localhost') === -1)  {
  document.domain = "matana.app";
  window.is_production = true;
}



Vue.mixin({
 
  data(){
    return {
     // url_pre: (window.is_production ? 'https://www.nadlancenter.co.il/community/' : 'http://fff/')
    }
  },
  computed: {
    color_array(){
    return [
      'rgba(204, 178, 134,1)',
      'rgba(192,192,192,1)',
      'rgba(205, 127, 50, 0.65)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 99, 132, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(255, 206, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(255, 159, 64, 0.2)'
    ];
  },
  
  border_array(){
    return [
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
      'rgba(255,255,255,1)',
    ];
  },
    userToken(){
      if(!this.isNull(localStorage.getItem('user_token'))) 
      {
        return true;
      }

      return false;
    },
    me(){
      return this.$store.user;
    }
  },
  methods: {
    scroll(value) {  
      var elmnt = document.getElementById(value);
        elmnt.scrollIntoView({behavior:'smooth'});
        },
    timeago(time){
      var timeago = this.$moment(time).fromNow(true);

      timeago = timeago.replace('a few seconds', 'עכשיו');
      timeago = timeago.replace('minutes', "דק'");
      timeago = timeago.replace('a minute', 'דקה');
      timeago = timeago.replace('hours', 'שעות');
      timeago = timeago.replace('an hour', 'שעה');
      timeago = timeago.replace('days', 'ימים');
      timeago = timeago.replace('a day', 'יום');
      timeago = timeago.replace('weeks', 'שבועות');
      timeago = timeago.replace('months', 'חודשים');
      timeago = timeago.replace('years', 'שנים');

      return timeago;
    },
    format_number: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },
    alert(msg){
      this.$alert(msg, '', '', { showDenyButton: false, confirmButtonText: 'אוקיי'});
    },
    confirm(msg){
      this.$confirm(msg, '', '', { showDenyButton: true, confirmButtonText: 'אוקיי'});
    },
    complete_signup(id)
    {
      console.log(this.$store.user)
        if(this.$store.user)
        {
         
          if(this.isNull(this.$store.user.job) || this.isNull(this.$store.user.company) || this.isNull(this.$store.user.display_name))
          {
            var link = '/profile/'+ id+'?edit=true'
            this.$confirm("עדין לא השלמת את הפרופיל שלך, לחץ כאן לעדכון הפרטים").then(() => {
             document.location = link
            });
          
         }
        }
       
   
    },
    get_me(){
      return this.$store.user;
    },
    parse_date(str){
      var d=new Date(str); return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },
    parse_time(str){
      var d=new Date(str); return (d.getHours() < 10 ? '0' + d.getHours() :d.getHours() ) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() :d.getMinutes() )+ ':' +(d.getSeconds() < 10 ? '0' + d.getSeconds() :d.getSeconds() ) ;
    },
     time_convert(num)
      { 
        var hours = Math.floor(num / 60);  
        var minutes = num % 60;
        if (hours > 0 && minutes > 0)
        {
          return hours + " שעות ו" + minutes + " דקות" ;         
        }
        else if(hours == 0 && minutes > 0)
        {
          return  minutes + " דקות" ;         
        }
        else if(hours > 0 && minutes == 0)
        {
          return  hours + " שעות" ;         
        }
      },

    parse_meta(meta)
    {
      if (this.isNull(meta)) return {};
   
      var ret = {};
      ret.meta = [];
      if (meta.title != '') 
      {
        ret.title = meta.title;
        ret.meta.push({name: 'og:title', content: meta.title }) 
      }
      if (meta.description != '')
      {
        ret.meta.push({name: 'og:description', content: meta.description }) 
        ret.meta.push({name: 'description', content: meta.description }) 
      }
      if (meta['og:image'] != '') ret.meta.push({property: 'og:image', content: meta['og:image']});

      return ret;
    },

  net_api: function(settings, func, failFunc){
    
    // var is_test = false;
    if (this.isNull(failFunc)) failFunc = function(){ };

     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
   

    // if (!is_test)
    // {

      var requestOBJ;

       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers, before(request){ requestOBJ = request; }})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers, before(request){ requestOBJ = request; }})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });

     }


     return requestOBJ;
     

   },




    api_call: function(settings){

        if (this.isNull(settings.onComplete))
        {
          settings.onComplete = function(){};
        }
        else
        {
          var real_func = settings.onComplete;
          settings.onComplete = function(data){
            real_func(data);
          };
        }
        
        if (this.isNull(settings.data)) settings.data = {};
        settings.data.api_user_token = localStorage.getItem('user_token');
        
        //return this.$http.post(window.api_url + settings.url + '?&ts=' + (new Date()).getHours() + '_' + ((new Date()).getMinutes() < 30 ? '0' : '30') , settings.data).then(settings.onComplete);
          return this.$http.post(window.api_url + settings.url , settings.data).then(settings.onComplete);

    },

    is_mobile: function(){ 

          return ( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      );
    },

    open_chat(id) {
      if (window.appVue.open_chats.indexOf(id) === -1)
      {
        window.appVue.open_chats.push(id);
      }
    },

    get_file_class(ext){
      var ret = '';
      switch (ext)
      {
        case 'doc':
        case 'docx': { ret = 'fas fa-file-word'; break;}

        case 'pdf': { ret = 'fas fa-file-pdf'; break;}
        
        case 'xls':
        case 'xlsx': { ret = 'fas fa-file-excel'; break;}
      }

      return ret;
    },
    

   }
  })


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

